import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
require('popper.js');
require('bootstrap');
import '@fortawesome/fontawesome-free/css/all.css';
import '@mdi/font/css/materialdesignicons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie'; window.Cookies = Cookies;
import jsSHA from 'jssha'; window.jsSHA = jsSHA;
import moment from 'moment'; window.moment = moment;
import Chart from 'chart.js'; window.Chart = Chart;
require('jquery.cookie');
require('jquery-countdown');
require('jquery-knob');
require('spectrum-colorpicker');
require('spectrum-colorpicker/i18n/jquery.spectrum-pl');
import 'spectrum-colorpicker/spectrum.css';
import Dropzone from 'dropzone'; window.Dropzone = Dropzone;
import 'dropzone/dist/min/dropzone.css';
import 'ekko-lightbox/dist/ekko-lightbox.css';
require('ekko-lightbox/dist/ekko-lightbox.js');
import 'jquery.fancytree/dist/skin-win8/ui.fancytree.min.css';
const fancytree = require('jquery.fancytree'); window.fancytree = fancytree;
require('jquery.fancytree/dist/modules/jquery.fancytree.dnd5');
require('jquery.fancytree/dist/modules/jquery.fancytree.edit');
require('jquery.fancytree/dist/modules/jquery.fancytree.gridnav');
require('jquery.fancytree/dist/modules/jquery.fancytree.table');
require('jquery.fancytree/dist/modules/jquery.fancytree.persist');
require('jquery.fancytree/dist/modules/jquery.fancytree.filter');
const { detect } = require('detect-browser'); const DetectBrowser = detect(); window.DetectBrowser = DetectBrowser;
const tinymce = require('tinymce/tinymce');
require('tinymce/themes/silver');
require('./tinymce/langs/pl.js');
window.tinymce = tinymce;

var ubermenu_data = {};
window.ubermenu_data = ubermenu_data;

import './bootstrap-datetimepicker/css/bootstrap-datetimepicker.css';
import './bootstrap-datetimepicker/js/bootstrap-datetimepicker.js';

//------------------------------------
// WCAG - dane
//------------------------------------
var whatInput = require('what-input');
window.whatInput = whatInput;

//------------------------------------
// mapu openlayers
//------------------------------------
import 'ol/ol.css';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { defaults as defaultControls, ScaleLine, ZoomSlider, OverviewMap, Attribution, FullScreen } from 'ol/control.js';
import Stamen from 'ol/source/Stamen.js';
import { fromLonLat, toLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile.js';
import TileWMS from 'ol/source/TileWMS.js';
import Overlay from 'ol/Overlay.js';
import XYZ from 'ol/source/XYZ.js';
import OSM from 'ol/source/OSM.js';
import Cluster from 'ol/source/Cluster.js';
import VectorSource from 'ol/source/Vector.js';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import MousePosition from 'ol/control/MousePosition.js';
//import OverviewMap from 'ol/control/OverviewMap';
//import Select from 'ol/interaction/Select';
import { createStringXY, toStringHDMS } from 'ol/coordinate.js';
import KML from 'ol/format/KML.js';
import { Heatmap as HeatmapLayer, Vector as VectorLayer } from 'ol/layer.js';
import 'ol-contextmenu/dist/ol-contextmenu.css';
import ContextMenu from 'ol-contextmenu/dist/ol-contextmenu.js';
import { defaults as defaultInteractions } from 'ol/interaction';
import { createEmpty, getWidth, getHeight, extend } from 'ol/extent';
//import proj4 from 'proj4'

//proj4.defs("EPSG:2177","+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");



//import { click, pointerMove, altKeyOnly } from 'ol/events/condition.js';
//import Projection from 'ol/proj/Projection';
//------------------------------------
// Wykresy
//------------------------------------
import ChartMap from 'ol-ext/style/Chart';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style.js';
//import { toContext } from 'ol/render.js';
//import Polygon from 'ol/geom/Polygon.js';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
//------------------------------------
// Na samym końcu ładujemy WE
//------------------------------------
import './index_we.css';
import { WEInit } from './index_we.js'; window.WE = window.WE = new WEInit();
//------------------------------------
// Generowanie URL dla Here
//------------------------------------
function mapLoadLayers(layerDesc) {
  var addobject = false;
  //------------------------------------
  //  ładujemy podkłady mapy od różnych prowiderów
  //------------------------------------
  if (layerDesc.provider == 'stamen') {
    addobject = true;
    var mapLayersObject = new TileLayer({
      opacity: layerDesc.opacity,
      visible: layerDesc.visible,
      source: new Stamen({ layer: layerDesc.scheme })
    });
  }
  else if (layerDesc.provider == 'osm') {
    addobject = true;
    var mapLayersObject = new TileLayer({
      opacity: layerDesc.opacity,
      visible: layerDesc.visible,
      source: new OSM({ layer: layerDesc.scheme })
    });
  }
  else if (layerDesc.provider == 'here') {
    addobject = true;
    var mapLayersObject = new TileLayer({
      opacity: layerDesc.opacity,
      visible: layerDesc.visible,
      preload: Infinity,
      source: new XYZ({ url: mapHereCreateUrl(layerDesc), attributions: layerDesc.attributions })
    });
  }
  else if (layerDesc.provider == 'xyz') {
    addobject = true;
    var mapLayersObject = new TileLayer({
      opacity: layerDesc.opacity,
      visible: layerDesc.visible,
      preload: Infinity,
      source: new XYZ({
        url: layerDesc.url,
        attributions: layerDesc.attributions
      })
    });
  }
  else if (layerDesc.provider == 'wms-geoserver') {
    addobject = true;
    var mapLayersObject = new TileLayer({
      opacity: layerDesc.opacity,
      visible: layerDesc.visible,
      source: new TileWMS({
        url: layerDesc.url,
        params: { 'layers': layerDesc.layers }, // , 'FORMAT': 'image/png', 'TILED': true, 'CRS': 'EPSG:900913', 'STYLES': ''
        serverType: 'geoserver',
        attributions: layerDesc.attributions,
        transition: 0
      })
    });
  }
  else if (layerDesc.provider == 'kml') {
    addobject = true;
    var mapLayersObject = new VectorLayer({
      visible: layerDesc.visible,
      opacity: layerDesc.opacity,
      source: new VectorSource({
        url: layerDesc.url,
        format: new KML(),
        attributions: layerDesc.attributions
      })
    });
  }
  else if (layerDesc.provider == 'kml-chart') {
    addobject = true;
    var mapLayersObject = new VectorLayer({
      visible: layerDesc.visible,
      opacity: layerDesc.opacity,
      source: new Cluster({
        distance: 40,
        source: new VectorSource({
          url: layerDesc.url,
          format: new KML({ extractStyles: false, extractAttributes: true })
        }),
        attributions: layerDesc.attributions
      }),
      style: function (feature, resolution) {
        var style;
        var size = feature.get('features').length;
        if (size > 1) {
          var extent = createEmpty();
          var features = feature.get('features');
          for (var i = features.length - 1; i >= 0; --i)  extend(extent, features[i].getGeometry().getExtent());
          var radius = 0.35 * (getWidth(extent) + getHeight(extent)) / resolution;
          style = new Style({
            image: new CircleStyle({
              radius: radius,
              fill: new Fill({ color: 'rgba(255, 153, 0, 0.4)' }), //[255, 153, 0, Math.min(0.8, (0.1 + (size / maxFeatureCount)))] 
              stroke: new Stroke({ color: 'rgba(255, 153, 0, 0.6)', width: 1 })
            }),
            text: new Text({
              text: size.toString(),
              scale: 1.5,
              fill: new Fill({ color: '#fff' }),
              stroke: new Stroke({ color: 'rgba(0, 0, 0, 0.6)', width: 2 })
            })
          });
        }
        else style = StyleChartKml(feature.get('features')[0], false);
        return style;
      }
    });
  }
  else if (layerDesc.provider == 'kml-marker') {
    addobject = true;
    var mapLayersObject = new VectorLayer({
      visible: layerDesc.visible,
      opacity: layerDesc.opacity,
      source: new VectorSource({
        url: layerDesc.url,
        format: new KML({ extractStyles: false, extractAttributes: true }),
        attributions: layerDesc.attributions
      }),
      style: function (feature) {
        var iconSrc = layerDesc.iconSrc;
        var iconAnchorX = layerDesc.iconAnchorX;
        var iconAnchorY = layerDesc.iconAnchorY;
        var anchorXUnits = layerDesc.anchorXUnits;
        var anchorYUnits = layerDesc.anchorYUnits;
        var iconOpacity = layerDesc.iconOpacity;
        var markerIconSrc = feature.get("markerIconSrc");
        var markericonAnchorX = feature.get("markerIconAnchorX");
        var markericonAnchorY = feature.get("markerIconAnchorY");
        var markeranchorXUnits = feature.get("markerAnchorXUnits");
        var markeranchorYUnits = feature.get("markerAnchorYUnits");
        var markericonOpacity = feature.get("markerIconOpacity");
        if (typeof (markerIconSrc) !== 'undefined') iconSrc = markerIconSrc;
        if (typeof (markericonAnchorX) !== 'undefined') iconAnchorX = markericonAnchorX;
        if (typeof (markericonAnchorY) !== 'undefined') iconAnchorY = markericonAnchorY;
        if (typeof (markeranchorXUnits) !== 'undefined') anchorXUnits = markeranchorXUnits;
        if (typeof (markeranchorYUnits) !== 'undefined') anchorYUnits = markeranchorYUnits;
        if (typeof (markericonOpacity) !== 'undefined') iconOpacity = markericonOpacity;
        var style = new Style({
          image: new Icon({
            anchor: [iconAnchorX, iconAnchorY],
            anchorXUnits: anchorXUnits,
            anchorYUnits: anchorYUnits,
            opacity: iconOpacity,
            src: iconSrc
          })
        });
        return style;
      }
    });
  }
  else if (layerDesc.provider == 'kml-marker-cluster') {
    addobject = true;
    var mapLayersObject = new VectorLayer({
      visible: layerDesc.visible,
      opacity: layerDesc.opacity,
      source: new Cluster({
        distance: 40,
        source: new VectorSource({
          url: layerDesc.url,
          format: new KML({ extractStyles: false, extractAttributes: true })
        }),
        attributions: layerDesc.attributions
      }),
      style: function (feature, resolution) {
        var style;
        var size = feature.get('features').length;
        if (size > 1) {
          var extent = createEmpty();
          var features = feature.get('features');
          for (var i = features.length - 1; i >= 0; --i)  extend(extent, features[i].getGeometry().getExtent());
          var radius = 0.45 * (getWidth(extent) + getHeight(extent)) / resolution;
          style = new Style({
            image: new CircleStyle({
              radius: radius,
              fill: new Fill({ color: 'rgba(255, 153, 0, 0.4)' }), //[255, 153, 0, Math.min(0.8, (0.1 + (size / maxFeatureCount)))] 
              stroke: new Stroke({ color: 'rgba(255, 153, 0, 0.6)', width: 1 })
            }),
            text: new Text({
              text: size.toString(),
              scale: 1.5,
              fill: new Fill({ color: '#fff' }),
              stroke: new Stroke({ color: 'rgba(0, 0, 0, 0.6)', width: 2 })
            })
          });
        } else {
          var iconSrc = layerDesc.iconSrc;
          var iconAnchorX = layerDesc.iconAnchorX;
          var iconAnchorY = layerDesc.iconAnchorY;
          var anchorXUnits = layerDesc.anchorXUnits;
          var anchorYUnits = layerDesc.anchorYUnits;
          var iconOpacity = layerDesc.iconOpacity;
          var markerIconSrc = feature.get("markerIconSrc");
          var markericonAnchorX = feature.get("markerIconAnchorX");
          var markericonAnchorY = feature.get("markerIconAnchorY");
          var markeranchorXUnits = feature.get("markerAnchorXUnits");
          var markeranchorYUnits = feature.get("markerAnchorYUnits");
          var markericonOpacity = feature.get("markerIconOpacity");
          if (typeof (markerIconSrc) !== 'undefined') iconSrc = markerIconSrc;
          if (typeof (markericonAnchorX) !== 'undefined') iconAnchorX = markericonAnchorX;
          if (typeof (markericonAnchorY) !== 'undefined') iconAnchorY = markericonAnchorY;
          if (typeof (markeranchorXUnits) !== 'undefined') anchorXUnits = markeranchorXUnits;
          if (typeof (markeranchorYUnits) !== 'undefined') anchorYUnits = markeranchorYUnits;
          if (typeof (markericonOpacity) !== 'undefined') iconOpacity = markericonOpacity;
          var style = new Style({
            image: new Icon({
              anchor: [iconAnchorX, iconAnchorY],
              anchorXUnits: anchorXUnits,
              anchorYUnits: anchorYUnits,
              opacity: iconOpacity,
              src: iconSrc
            })
          });
        }
        return style;
      }
    });
  }
  else if (layerDesc.provider == 'kml-heatmap') {
    /*
    var blur = document.getElementById('layer-heatmap-blur');
    var radius = document.getElementById('layer-heatmap-radius');

    var Heatmap = new HeatmapLayer({
      opacity: layerDesc.opacity, 
      visible: layerDesc.visible,
      source: new VectorSource({
        url: layerDesc.url,
        format: new KML({
          extractStyles: false
        }),
         attributions: layerDesc.attributions
      }),
      blur: parseInt(blur.value, 10),
      radius: parseInt(radius.value, 10)
    });

    Heatmap.set('provider', layerDesc.provider);
    Heatmap.set('id', layerDesc.layerid);
    Heatmap.set('selectable', layerDesc.selectable);

    Heatmap.getSource().on('addfeature', function (event) {
      var name = event.feature.get('name');
      var magnitude = parseFloat(name.substr(2));
      event.feature.set('weight', magnitude - 5);
    });

    blur.addEventListener('input', function () {
      Heatmap.setBlur(parseInt(blur.value, 10));
    });

    radius.addEventListener('input', function () {
      Heatmap.setRadius(parseInt(radius.value, 10));
    });

    mapLayers.push(Heatmap);
    */
  }
  else if (layerDesc.provider == 'geojson') {
    addobject = true;
    var mapLayersObject = new VectorLayer({
      opacity: layerDesc.opacity,
      visible: layerDesc.visible,
      source: new VectorSource({
        url: layerDesc.url,
        format: new GeoJSON(),
        attributions: layerDesc.attributions
      })
    });
  }
  else if (layerDesc.provider == 'geojson-marker') {
    addobject = true;
    var mapLayersObject = new VectorLayer({
      opacity: layerDesc.opacity,
      visible: layerDesc.visible,
      source: new VectorSource({
        url: layerDesc.url,
        format: new GeoJSON(),
        attributions: layerDesc.attributions
      }),
      style: function (feature) {
        var iconSrc = layerDesc.iconSrc;
        var iconAnchorX = layerDesc.iconAnchorX;
        var iconAnchorY = layerDesc.iconAnchorY;
        var anchorXUnits = layerDesc.anchorXUnits;
        var anchorYUnits = layerDesc.anchorYUnits;
        var iconOpacity = layerDesc.iconOpacity;
        var markerIconSrc = feature.get("markerIconSrc");
        var markericonAnchorX = feature.get("markerIconAnchorX");
        var markericonAnchorY = feature.get("markerIconAnchorY");
        var markeranchorXUnits = feature.get("markerAnchorXUnits");
        var markeranchorYUnits = feature.get("markerAnchorYUnits");
        var markericonOpacity = feature.get("markerIconOpacity");
        if (typeof (markerIconSrc) !== 'undefined') iconSrc = markerIconSrc;
        if (typeof (markericonAnchorX) !== 'undefined') iconAnchorX = markericonAnchorX;
        if (typeof (markericonAnchorY) !== 'undefined') iconAnchorY = markericonAnchorY;
        if (typeof (markeranchorXUnits) !== 'undefined') anchorXUnits = markeranchorXUnits;
        if (typeof (markeranchorYUnits) !== 'undefined') anchorYUnits = markeranchorYUnits;
        if (typeof (markericonOpacity) !== 'undefined') iconOpacity = markericonOpacity;
        var style = new Style({
          image: new Icon({
            anchor: [iconAnchorX, iconAnchorY],
            anchorXUnits: anchorXUnits,
            anchorYUnits: anchorYUnits,
            opacity: iconOpacity,
            src: iconSrc
          })
        });
        return style;
      }
    });
  }
  else if (layerDesc.provider == 'geojson-marker-cluster') {
    addobject = true;
    var mapLayersObject = new VectorLayer({
      visible: layerDesc.visible,
      opacity: layerDesc.opacity,
      source: new Cluster({
        distance: 40,
        source: new VectorSource({
          url: layerDesc.url,
          format: new GeoJSON(),
        }),
        attributions: layerDesc.attributions
      }),
      style: function (feature, resolution) {
        var style;
        var size = feature.get('features').length;
        if (size > 1) {
          var extent = createEmpty();
          var features = feature.get('features');
          for (var i = features.length - 1; i >= 0; --i)  extend(extent, features[i].getGeometry().getExtent());
          var radius = 0.5 * (getWidth(extent) + getHeight(extent)) / resolution;
          style = new Style({
            image: new CircleStyle({
              radius: radius,
              fill: new Fill({ color: 'rgba(255, 153, 0, 0.4)' }), //[255, 153, 0, Math.min(0.8, (0.1 + (size / maxFeatureCount)))] 
              stroke: new Stroke({ color: 'rgba(255, 153, 0, 0.6)', width: 1 })
            }),
            text: new Text({
              text: size.toString(),
              scale: 1.5,
              fill: new Fill({ color: '#fff' }),
              stroke: new Stroke({ color: 'rgba(0, 0, 0, 0.6)', width: 2 })
            })
          });
        } else {
          var iconSrc = layerDesc.iconSrc;
          var iconAnchorX = layerDesc.iconAnchorX;
          var iconAnchorY = layerDesc.iconAnchorY;
          var anchorXUnits = layerDesc.anchorXUnits;
          var anchorYUnits = layerDesc.anchorYUnits;
          var iconOpacity = layerDesc.iconOpacity;
          var markerIconSrc = feature.get("markerIconSrc");
          var markericonAnchorX = feature.get("markerIconAnchorX");
          var markericonAnchorY = feature.get("markerIconAnchorY");
          var markeranchorXUnits = feature.get("markerAnchorXUnits");
          var markeranchorYUnits = feature.get("markerAnchorYUnits");
          var markericonOpacity = feature.get("markerIconOpacity");
          if (typeof (markerIconSrc) !== 'undefined') iconSrc = markerIconSrc;
          if (typeof (markericonAnchorX) !== 'undefined') iconAnchorX = markericonAnchorX;
          if (typeof (markericonAnchorY) !== 'undefined') iconAnchorY = markericonAnchorY;
          if (typeof (markeranchorXUnits) !== 'undefined') anchorXUnits = markeranchorXUnits;
          if (typeof (markeranchorYUnits) !== 'undefined') anchorYUnits = markeranchorYUnits;
          if (typeof (markericonOpacity) !== 'undefined') iconOpacity = markericonOpacity;
          var style = new Style({
            image: new Icon({
              anchor: [iconAnchorX, iconAnchorY],
              anchorXUnits: anchorXUnits,
              anchorYUnits: anchorYUnits,
              opacity: iconOpacity,
              src: iconSrc
            })
          });

        }
        return style;
      }
    });
  }
  else if (layerDesc.provider == 'marker-array') {
    if (layerDesc.markers.length > 0) {
      var features = [];
      for (var m = 0; m < layerDesc.markers.length; m++) {
        var marekrElem = new Feature({
          geometry: new Point(fromLonLat([layerDesc.markers[m].lon, layerDesc.markers[m].lat])),
          name: layerDesc.markers[m].name,
          description: layerDesc.markers[m].description
        })
        marekrElem.setStyle(new Style({
          image: new Icon({
            anchor: [layerDesc.markers[m].iconAnchorX, layerDesc.markers[m].iconAnchorY],
            anchorXUnits: layerDesc.markers[m].anchorXUnits,
            anchorYUnits: layerDesc.markers[m].anchorYUnits,
            opacity: layerDesc.markers[m].iconOpacity,
            src: layerDesc.markers[m].iconSrc
          })
        }));
        features.push(marekrElem);
      }
      //publikujemy jeśli cokolwiek zostało załadowane
      addobject = true;
      var mapLayersObject = new VectorLayer({
        opacity: layerDesc.opacity,
        visible: layerDesc.visible,
        source: new VectorSource({
          features: features,
          attributions: layerDesc.attributions
        })
      });
    }
  }
  //------------------------------------
  // odkładamy na stos element mapy
  //------------------------------------
  return addobject ? mapLayersObject : null;
}
//------------------------------------
// odczytujemy zawartość elementu kliknietego
//------------------------------------
function mapGeocodingInfo(elemCountry, elemPostcode, elemCity, elemVillage, elemRoad, elemHouseNumber, elemInfo, Lon, Lat) {
  fetch('https://nominatim.openstreetmap.org/reverse?format=json&addressdetails=1&limit=1&lon=' + Lon + '&lat=' + Lat).then(function (response) { return response.json(); }).then(function (json) {
    if (typeof elemCountry !== "undefined") {
      if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.country !== "undefined" && json.address.country !== '') jQuery('#' + elemCountry).val(json.address.country);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.local_administrative_area !== "undefined" && json.address.local_administrative_area !== '') jQuery('#' + elemCountry).val(json.address.local_administrative_area);
      else jQuery('#' + elemCountry).val('');
    }
    if (typeof elemPostcode !== "undefined") {
      if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.postcode !== "undefined") jQuery('#' + elemPostcode).val(json.address.postcode);
      else jQuery('#' + elemPostcode).val('');
    }
    if (typeof elemCity !== "undefined") {
      if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.city !== "undefined" && json.address.city !== '') jQuery('#' + elemCity).val(json.address.city);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.town !== "undefined" && json.address.town !== '') jQuery('#' + elemCity).val(json.address.town);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.municipality !== "undefined" && json.address.municipality !== '') jQuery('#' + elemCity).val(json.address.municipality);
      else jQuery('#' + elemCity).val('');
    }
    if (typeof elemVillage !== "undefined") {
      if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.village !== "undefined" && json.address.village !== '') jQuery('#' + elemVillage).val(json.address.village);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.hamlet !== "undefined" && json.address.hamlet !== '') jQuery('#' + elemVillage).val(json.address.hamlet);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.locality !== "undefined" && json.address.locality !== '') jQuery('#' + elemVillage).val(json.address.locality);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.croft !== "undefined" && json.address.croft !== '') jQuery('#' + elemVillage).val(json.address.croft);
      else jQuery('#' + elemVillage).val('');
    }
    if (typeof elemRoad !== "undefined") {
      if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.road !== "undefined" && json.address.road !== '') jQuery('#' + elemRoad).val(json.address.road);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.footway !== "undefined" && json.address.footway !== '') jQuery('#' + elemRoad).val(json.address.footway);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.street !== "undefined" && json.address.street !== '') jQuery('#' + elemRoad).val(json.address.street);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.street_name !== "undefined" && json.address.street_name !== '') jQuery('#' + elemRoad).val(json.address.street_name);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.residential !== "undefined" && json.address.residential !== '') jQuery('#' + elemRoad).val(json.address.residential);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.path !== "undefined" && json.address.path !== '') jQuery('#' + elemRoad).val(json.address.path);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.pedestrian !== "undefined" && json.address.pedestrian !== '') jQuery('#' + elemRoad).val(json.address.pedestrian);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.road_reference !== "undefined" && json.address.road_reference !== '') jQuery('#' + elemRoad).val(json.address.road_reference);
      else if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.road_reference_intl !== "undefined" && json.address.road_reference_intl !== '') jQuery('#' + elemRoad).val(json.address.road_reference_intl);
      else jQuery('#' + elemRoad).val('');
    }
    if (typeof elemHouseNumber !== "undefined") {
      if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.address.house_number !== "undefined" && json.address.house_number !== '') jQuery('#' + elemHouseNumber).val(json.address.house_number);
      else jQuery('#' + elemHouseNumber).val('');
    }
    if (typeof elemInfo !== "undefined") {
      if (typeof json !== "undefined" && typeof json.address !== "undefined" && typeof json.display_name !== "undefined") jQuery('#' + elemInfo).val(json.display_name);
      else jQuery('#' + elemInfo).val('');
    }
  })
}
//------------------------------------
// Generowanie URL dla Here
//------------------------------------
function mapHereCreateUrl(layerDesc) { return layerDesc.url.replace('{base}', layerDesc.base).replace('{type}', layerDesc.type).replace('{scheme}', layerDesc.scheme).replace('{app_id}', layerDesc.app_id).replace('{app_code}', layerDesc.app_code); }
//------------------------------------
// Obsługa wykresów
//------------------------------------
function StyleChartKml(feature, sel) {

  if (typeof (feature.get("chartElem")) !== 'undefined' && typeof (feature.get("chartType")) !== 'undefined' && typeof (feature.get("chartColors")) !== 'undefined') {
    var chartType = feature.get("chartType"); //"donut", "pie3D", "pie"
    var chartColors = feature.get("chartColors"); //"classic", "dark", "pale", "pastel", "neon"
    var chartElem = Number(feature.get("chartElem"));
    var chartRadius = Number(feature.get("chartRadius"));
    var sum = 0;
    var data = [];
    //var datadescription = [];
    for (var i = 0; i < chartElem; i++) {
      if (typeof (feature.get("value" + i)) !== 'undefined') {
        var value = Number(feature.get("value" + i));
        sum += value;
        data.push(value);
      }
    }
    var radius = (chartRadius ? chartRadius : 25);
    radius *= (sel ? 1.3 : 1);
    // Create chart style
    var style = [new Style({
      image: new ChartMap({
        type: chartType,
        radius: radius,
        data: data,
        rotateWithView: true,
        colors: chartColors,
        stroke: new Stroke({ color: "#fff", width: 2 }),
      })
    })];
    // Show values on select
    if (sel) {
      var s = 0;
      for (var i = 0; i < data.length; i++) {
        var d = data[i];
        var a = (2 * s + d) / sum * Math.PI - Math.PI / 2;
        var v = Math.round(d / sum * 1000);
        if (v > 0) {
          style.push(new Style({
            text: new Text({
              text: (v / 10) + "% " + d.toString(),
              offsetX: Math.cos(a) * (radius + 5),
              offsetY: Math.sin(a) * (radius + 5),
              textAlign: (a < Math.PI / 2 ? "left" : "right"),
              textBaseline: "middle",
              stroke: new Stroke({ color: "#fff", width: 5 }),
              fill: new Fill({ color: "#000" })
            })
          }));
        }
        s += d;
      }
    }
    return style;
  }
}
//--------------------------------------------------------
// Zmiana rozdzielczosci wymaga przerysowania
//--------------------------------------------------------
function KnobResize() {
  jQuery('.container-countdown').each(function () {
    var $this = jQuery(this), finalDate = jQuery(this).data('countdown');
    $this.countdown(finalDate, function (event) {
      var secs = event.strftime('%S'), mins = event.strftime('%M'), hr = event.strftime('%H'), ds = event.strftime('%D');
      $this.find(".countdown-ds").val(ds).trigger("change");
      $this.find(".countdown-hr").val(hr).trigger("change");
      $this.find(".countdown-min").val(mins).trigger("change");
      $this.find(".countdown-sec").val(secs).trigger("change");
    });

    jQuery('.knob').knob();

    var width = Math.floor(($this.width()) / 4);
    $this.find('.knob').trigger('configure', { width: width, height: width });
  });
}

//--------------------------------------------------------
// Zmiana rozdzielczosci wymaga przerysowania
//--------------------------------------------------------
function MenuResize() {

  if (jQuery('#we-content-menu-top').length) {
    var scrollTop = jQuery(window).scrollTop();
    var menuTopHeight = jQuery('#we-content-menu-top').height();
    var menuTopPosition = jQuery('#we-content-menu-top').position().top;

    if (scrollTop > menuTopPosition) {
      jQuery('#we-content-menu-top').css('position', 'fixed').css('top', 0);
      jQuery('#we-content-menu-top-margin').css('height', menuTopHeight);
    }
    else {
      jQuery('#we-content-menu-top').css('position', 'relative').css('top', 0);
      jQuery('#we-content-menu-top-margin').css('height', 0);
    }
  }
}

//------------------------------------
// Uruchamiamy skrypty gdy się skroluje strone
//------------------------------------
jQuery(window).on('scroll', function () {
  MenuResize();
});
//------------------------------------
// Uruchamiamy skrypty gdy się zmieni rozdzielczość
//------------------------------------
jQuery(window).on('resize', function () {
  KnobResize();
  MenuResize();
});
//------------------------------------
// Uruchamiamy skrypty gdy się załaduje portal
//------------------------------------
jQuery(document).ready(function () {

  //console.log(DetectBrowser);
  if (typeof (DetectBrowser.name) !== 'undefined') jQuery('body').addClass('we-browser-name-' + DetectBrowser.name.replace(/[^a-z0-9]/ig, '').toLowerCase());
  if (typeof (DetectBrowser.type) !== 'undefined') jQuery('body').addClass('we-browser-type-' + DetectBrowser.type.replace(/[^a-z0-9]/ig, '').toLowerCase());
  if (typeof (DetectBrowser.os) !== 'undefined') jQuery('body').addClass('we-browser-os-' + DetectBrowser.os.replace(/[^a-z0-9]/ig, '').toLowerCase());
  if (typeof (DetectBrowser.version) !== 'undefined') jQuery('body').addClass('we-browser-version-' + DetectBrowser.version.replace(/[^a-z0-9]/ig, '').toLowerCase());

  //--------------------------------------
  // dla wszystkich elementów formularzy - dodatki
  //--------------------------------------  
  jQuery(".we-form-control").on("change keyup paste click", function () {

    var datatype = jQuery(this).attr("data-type");
    var required = jQuery(this).prop('required');
    var displayonoff = jQuery(this).attr("data-displayonoff-checkboxchecked");
    //--------------------------------------
    // mechanizm pokaz ukryj element ID na podstawie statusu checkbox
    //--------------------------------------
    if ((typeof (displayonoff) !== 'undefined')) {
      if (jQuery(this).is(':checked')) jQuery('#' + displayonoff).removeClass('d-none');
      else jQuery('#' + displayonoff).addClass('d-none');
    }
    //--------------------------------------
    //--------------------------------------
    if (datatype == 'checkbox') {
      if ((typeof (required) !== 'undefined') && (required == true)) {
        var checked = jQuery(this).prop('checked');
        if (checked) jQuery(this).removeClass('is-invalid').addClass('is-valid');
        else jQuery(this).removeClass('is-valid').addClass('is-invalid');
      }
    }
    //--------------------------------------
    // Weryfikacja PESEL
    //--------------------------------------
    else if (datatype == 'pesel') {
      var value = jQuery(this).val();
      value = value.replace(/[^\d]/g, '');
      jQuery(this).val(value);
      if ((value.length > 0) || ((typeof (required) !== 'undefined') && (required == true))) {
        var bladpattern = true;
        var pattern = new RegExp(/^[0-9]{11}$/g);
        var result = pattern.test(value);
        if (result) {
          var suma_kont = 0;
          suma_kont += parseInt(value.substring(0, 1)) * 1;
          suma_kont += parseInt(value.substring(1, 2)) * 3;
          suma_kont += parseInt(value.substring(2, 3)) * 7;
          suma_kont += parseInt(value.substring(3, 4)) * 9;
          suma_kont += parseInt(value.substring(4, 5)) * 1;
          suma_kont += parseInt(value.substring(5, 6)) * 3;
          suma_kont += parseInt(value.substring(6, 7)) * 7;
          suma_kont += parseInt(value.substring(7, 8)) * 9;
          suma_kont += parseInt(value.substring(8, 9)) * 1;
          suma_kont += parseInt(value.substring(9, 10)) * 3;
          var tmp = 10 - suma_kont % 10;
          tmp = ((tmp == 10) ? 0 : tmp);
          if (tmp == parseInt(value.substring(10, 11))) bladpattern = false;
        }
        if (bladpattern) jQuery(this).removeClass('is-valid').addClass('is-invalid');
        else jQuery(this).removeClass('is-invalid').addClass('is-valid');
      }
      else jQuery(this).removeClass('is-invalid').removeClass('is-valid');
    }
    //--------------------------------------
    // weryfikacja NIP
    //--------------------------------------
    else if (datatype == 'nip') {
      var value = jQuery(this).val();
      value = value.replace(/[^\d]/g, '');
      jQuery(this).val(value);
      if ((value.length > 0) || ((typeof (required) !== 'undefined') && (required == true))) {
        var bladpattern = true;
        var pattern = new RegExp(/^[0-9]{10}$/g);
        var result = pattern.test(value);
        if (result) {
          var suma_kont = 0;
          suma_kont += parseInt(value.substring(0, 1)) * 6;
          suma_kont += parseInt(value.substring(1, 2)) * 5;
          suma_kont += parseInt(value.substring(2, 3)) * 7;
          suma_kont += parseInt(value.substring(3, 4)) * 2;
          suma_kont += parseInt(value.substring(4, 5)) * 3;
          suma_kont += parseInt(value.substring(5, 6)) * 4;
          suma_kont += parseInt(value.substring(6, 7)) * 5;
          suma_kont += parseInt(value.substring(7, 8)) * 6;
          suma_kont += parseInt(value.substring(8, 9)) * 7;
          var tmp = suma_kont % 11;
          if (tmp == parseInt(value.substring(9, 10))) bladpattern = false;
        }
        if (bladpattern) jQuery(this).removeClass('is-valid').addClass('is-invalid');
        else jQuery(this).removeClass('is-invalid').addClass('is-valid');
      }
      else jQuery(this).removeClass('is-invalid').removeClass('is-valid');
    }
    //--------------------------------------
    // weryfikacja REGON
    //--------------------------------------
    else if (datatype == 'regon') {
      var value = jQuery(this).val();
      value = value.replace(/[^\d]/g, '');
      jQuery(this).val(value);
      if ((value.length > 0) || ((typeof (required) !== 'undefined') && (required == true))) {

        // uzupełniamy zero
        while (value.length < 9) value = '0' + value;

        var bladpattern = true;
        var pattern = new RegExp(/^[0-9]{9}$/g);
        var result = pattern.test(value);
        if (result) {
          var suma_kont = 0;
          suma_kont += parseInt(value.substring(0, 1)) * 8;
          suma_kont += parseInt(value.substring(1, 2)) * 9;
          suma_kont += parseInt(value.substring(2, 3)) * 2;
          suma_kont += parseInt(value.substring(3, 4)) * 3;
          suma_kont += parseInt(value.substring(4, 5)) * 4;
          suma_kont += parseInt(value.substring(5, 6)) * 5;
          suma_kont += parseInt(value.substring(6, 7)) * 6;
          suma_kont += parseInt(value.substring(7, 8)) * 7;

          var tmp = suma_kont % 11;
          if (tmp == parseInt(value.substring(8, 9))) bladpattern = false;
        }
        else {
          var pattern = new RegExp(/^[0-9]{14}$/g);
          var result = pattern.test(value);
          if (result) {
            var suma_kont = 0;
            suma_kont += parseInt(value.substring(0, 1)) * 2;
            suma_kont += parseInt(value.substring(1, 2)) * 4;
            suma_kont += parseInt(value.substring(2, 3)) * 8;
            suma_kont += parseInt(value.substring(3, 4)) * 5;
            suma_kont += parseInt(value.substring(4, 5)) * 0;
            suma_kont += parseInt(value.substring(5, 6)) * 9;
            suma_kont += parseInt(value.substring(6, 7)) * 7;
            suma_kont += parseInt(value.substring(7, 8)) * 3;
            suma_kont += parseInt(value.substring(8, 9)) * 6;
            suma_kont += parseInt(value.substring(9, 10)) * 1;
            suma_kont += parseInt(value.substring(10, 11)) * 2;
            suma_kont += parseInt(value.substring(11, 12)) * 4;
            suma_kont += parseInt(value.substring(12, 13)) * 8;

            var tmp = suma_kont % 11;
            if (tmp == parseInt(value.substring(13, 14))) bladpattern = false;
          }
        }
        if (bladpattern) jQuery(this).removeClass('is-valid').addClass('is-invalid');
        else jQuery(this).removeClass('is-invalid').addClass('is-valid');
      }
      else jQuery(this).removeClass('is-invalid').removeClass('is-valid');
    }
    //--------------------------------------
    // konto bankowe
    //--------------------------------------
    else if (datatype == 'nrb') {
      var value = jQuery(this).val();
      value = value.replace(/[^\d]/g, '');
      jQuery(this).val(value);
      if ((value.length > 0) || ((typeof (required) !== 'undefined') && (required == true))) {
        var bladpattern = true;
        var pattern = new RegExp(/^[0-9]{26}$/g);
        var result = pattern.test(value);
        if (result) {
          var wagi = new Array(1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57);
          value = value + "2521";
          value = value.substr(2) + value.substr(0, 2);
          var Z = 0;
          for (var i = 0; i < 30; i++) { Z += value[29 - i] * wagi[i]; }
          if (Z % 97 == 1) bladpattern = false;
        }
        if (bladpattern) jQuery(this).removeClass('is-valid').addClass('is-invalid');
        else jQuery(this).removeClass('is-invalid').addClass('is-valid');
      }
      else jQuery(this).removeClass('is-invalid').removeClass('is-valid');
    }
    //--------------------------------------
    //--------------------------------------
    else {
      var value = jQuery(this).val();
      var pattern = jQuery(this).attr("pattern");

      if (datatype == 'text-number-decimal') {
        value = value.replace(',', '.').replace(/[^\d\.\-]/g, '');
        var match = (/(-?\d*)[^\.]*((?:\.\d{0,2})?)/g).exec(value.replace(/[^\d\.\-]/g, ''));
        value = match[1] + match[2];
        jQuery(this).val(value);
      }
      else if (datatype == 'text-number-decimal-double') {
        value = value.replace(',', '.').replace(/[^\d\.\-]/g, '');
        var match = (/(-?\d*)[^\.]*((?:\.\d{0,15})?)/g).exec(value.replace(/[^\d\.\-]/g, ''));
        value = match[1] + match[2];
        jQuery(this).val(value);
      }
      else if (datatype == 'text-number-tinyint' || datatype == 'text-number-int' || datatype == 'text-number-bigint' || datatype == 'phonenumber') {
        value = value.replace(/[^\d\-]/g, '');
        jQuery(this).val(value);
      }
      else if (datatype == 'text-year') {
        value = value.replace(/[^\d\-]/g, '');
        jQuery(this).val(value);
      }
      // kolorujemy tylko gdy pole jest wypełnione i ma required lub pattern wlączone
      if ((value.length > 0) || ((typeof (required) !== 'undefined') && (required == true))) {
        var FieldCheck = false;
        var FieldValid = false;
        // sprawdzamy wartość dla required - jakakolwiek wpisana tresć dla value
        if ((typeof (required) !== 'undefined') && (required == true)) {
          FieldCheck = true;
          FieldValid = ((typeof (value) !== 'undefined') && (value.length > 0)) ? true : false;
        }
        // sprawdzamy gdy włączono patern niezaleznie od required (gdy jest wpisany)
        if ((typeof (value) !== 'undefined') && (value.length > 0) && (typeof (pattern) !== 'undefined') && (pattern.length > 0)) {
          FieldCheck = true;
          var RegObj = new RegExp('^' + pattern + '$', "gm");
          if (RegObj !== null && typeof (RegObj) === 'object') FieldValid = (RegObj.test(value)) ? true : false;
          else FieldValid = false;
        }
        if (FieldCheck) {
          if (FieldValid) jQuery(this).removeClass('is-invalid').addClass('is-valid');
          else jQuery(this).removeClass('is-valid').addClass('is-invalid');
        }
      }
      else jQuery(this).removeClass('is-invalid').removeClass('is-valid');
    }
  });

  //--------------------------------------
  // DateTime picker
  //--------------------------------------

  jQuery(".we-datetimepicker-datetime").each(function () {
    var startDate = jQuery(this).attr("date-startdate"); if (typeof (startDate) == 'undefined') startDate = null;
    var endDate = jQuery(this).attr("date-enddate"); if (typeof (endDate) == 'undefined') endDate = null;
    jQuery(this).datetimepicker({ format: "yyyy-mm-dd hh:ii:00", todayBtn: true, language: "pl", autoclose: true, startDate: startDate, endDate: endDate, clearBtn: true });
    if (typeof (jQuery(this).attr("date-disabled-weekend")) !== 'undefined') jQuery(this).datetimepicker('setDaysOfWeekDisabled', [0, 6]);
  });

  jQuery(".we-datetimepicker-datetime-noseconds").each(function () {
    var startDate = jQuery(this).attr("date-startdate"); if (typeof (startDate) == 'undefined') startDate = null;
    var endDate = jQuery(this).attr("date-enddate"); if (typeof (endDate) == 'undefined') endDate = null;
    jQuery(this).datetimepicker({ format: "yyyy-mm-dd hh:ii", todayBtn: true, language: "pl", autoclose: true, startDate: startDate, endDate: endDate, clearBtn: true });
    if (typeof (jQuery(this).attr("date-disabled-weekend")) !== 'undefined') jQuery(this).datetimepicker('setDaysOfWeekDisabled', [0, 6]);
  });

  jQuery(".we-datetimepicker-date").each(function () {
    var startDate = jQuery(this).attr("date-startdate"); if (typeof (startDate) == 'undefined') startDate = null;
    var endDate = jQuery(this).attr("date-enddate"); if (typeof (endDate) == 'undefined') endDate = null;
    jQuery(this).datetimepicker({ format: "yyyy-mm-dd", language: "pl", todayBtn: true, autoclose: true, startDate: startDate, endDate: endDate, clearBtn: true, minView: 2 });
    if (typeof (jQuery(this).attr("date-disabled-weekend")) !== 'undefined') jQuery(this).datetimepicker('setDaysOfWeekDisabled', [0, 6]);
  });

  jQuery(".we-datetimepicker-time").each(function () {
    var startDate = jQuery(this).attr("date-startdate"); if (typeof (startDate) == 'undefined') startDate = null;
    var endDate = jQuery(this).attr("date-enddate"); if (typeof (endDate) == 'undefined') endDate = null;
    jQuery(this).datetimepicker({ format: "hh:ii:00", language: "pl", todayBtn: true, autoclose: true, startDate: startDate, endDate: endDate, clearBtn: true });
    if (typeof (jQuery(this).attr("date-disabled-weekend")) !== 'undefined') jQuery(this).datetimepicker('setDaysOfWeekDisabled', [0, 6]);
  });

  jQuery(".we-datetimepicker-time-noseconds").each(function () {
    var startDate = jQuery(this).attr("date-startdate"); if (typeof (startDate) == 'undefined') startDate = null;
    var endDate = jQuery(this).attr("date-enddate"); if (typeof (endDate) == 'undefined') endDate = null;
    //var datesDisabled = [];
    jQuery(this).datetimepicker({ format: "hh:ii", language: "pl", todayBtn: true, autoclose: true, startDate: startDate, endDate: endDate, clearBtn: true });
    if (typeof (jQuery(this).attr("date-disabled-weekend")) !== 'undefined') jQuery(this).datetimepicker('setDaysOfWeekDisabled', [0, 6]);
  });

  jQuery('.we-we-datetimepicker-datetime.we-datetimepicker-setdate').each(function () {
    var startDate = jQuery(this).attr("date-startdate"); if (typeof (startDate) == 'undefined') startDate = null;
    var endDate = jQuery(this).attr("date-enddate"); if (typeof (endDate) == 'undefined') endDate = null;
    jQuery(this).datetimepicker({ format: "yyyy-mm-dd hh:ii:00", todayBtn: true, language: "pl", autoclose: true, startDate: startDate, endDate: endDate, clearBtn: true });
    jQuery(this).datetimepicker('setDate', new Date());
    if (typeof (jQuery(this).attr("date-disabled-weekend")) !== 'undefined') jQuery(this).datetimepicker('setDaysOfWeekDisabled', [0, 6]);
  });

  jQuery('.we-datetimepicker-date.we-datetimepicker-setdate').each(function () {
    var startDate = jQuery(this).attr("date-startdate"); if (typeof (startDate) == 'undefined') startDate = null;
    var endDate = jQuery(this).attr("date-enddate"); if (typeof (endDate) == 'undefined') endDate = null;
    jQuery(this).datetimepicker({ format: "yyyy-mm-dd", language: "pl", todayBtn: true, autoclose: true, startDate: startDate, endDate: endDate, clearBtn: true, minView: 2 });
    jQuery(this).datetimepicker('setDate', new Date());
    if (typeof (jQuery(this).attr("date-disabled-weekend")) !== 'undefined') jQuery(this).datetimepicker('setDaysOfWeekDisabled', [0, 6]);
  });
  //--------------------------------------
  // Lightbox
  //--------------------------------------
  jQuery(document).on('click', '[data-toggle="lightbox"]', function (event) { event.preventDefault(); jQuery(this).ekkoLightbox(); });
  //--------------------------------------
  // Colorpicker
  //--------------------------------------
  var spectrum_palette = [["#000", "#444", "#666", "#999", "#ccc", "#eee", "#f3f3f3", "#fff"], ["#f00", "#f90", "#ff0", "#0f0", "#0ff", "#00f", "#90f", "#f0f"], ["#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#cfe2f3", "#d9d2e9", "#ead1dc"], ["#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#9fc5e8", "#b4a7d6", "#d5a6bd"], ["#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6fa8dc", "#8e7cc3", "#c27ba0"], ["#c00", "#e69138", "#f1c232", "#6aa84f", "#45818e", "#3d85c6", "#674ea7", "#a64d79"], ["#900", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#0b5394", "#351c75", "#741b47"], ["#600", "#783f04", "#7f6000", "#274e13", "#0c343d", "#073763", "#20124d", "#4c1130"]];
  jQuery(".we-colorpicker").spectrum({ showInput: true, showInitial: true, allowEmpty: true, showAlpha: true, showPalette: true, preferredFormat: "rgb", palette: spectrum_palette });
  jQuery(".we-colorpicker-hex").spectrum({ showInput: true, showInitial: true, allowEmpty: true, showAlpha: false, showPalette: true, preferredFormat: "hex", palette: spectrum_palette });
  //--------------------------------------
  // na potrzby sumowania pozycji w zestawieniu - moduł bazy danych 850
  //--------------------------------------
  jQuery(".we-form-calculate-elements").on("change keyup paste click", function () { var CSum = 0; var dataCalculate = jQuery(this).attr("data-calculate-elements"); var dataCalculateSum = jQuery(this).attr("data-calculate-sum"); jQuery('.' + dataCalculate).each(function () { var value = jQuery(this).val(); if (value.length > 0) { CSum += parseFloat(value); } }); jQuery('.' + dataCalculateSum).val(CSum.toFixed(2)); });
  //--------------------------------------
  // na potrzby nawigacji w stronach www i cms
  //--------------------------------------
  jQuery(".we-button-pagination-submit").on("click", function () {
    var operation = jQuery(this).attr("data-operation");
    var target = jQuery(this).attr("data-target");
    if (operation == "next") {
      jQuery("#" + target + " option:selected").next().attr("selected", "selected");
      jQuery("#Formularz").trigger("submit");
    }
    else if (operation == "prev") {
      jQuery("#" + target + " option:selected").prev().attr("selected", "selected");
      jQuery("#Formularz").trigger("submit");
    }
    else if (operation == "last") {
      jQuery("#" + target + " option:last").attr("selected", "selected");
      jQuery("#Formularz").trigger("submit");
    }
    else {
      jQuery("#" + target + " option:first").attr("selected", "selected");
      jQuery("#Formularz").trigger("submit");
    }
  });

  //--------------------------------------------------------
  // dla wszystkich elementów textarea w clasie tinymce uruchamiamy edytor
  //--------------------------------------------------------

  if (typeof tinymce != 'undefined') {
    if (jQuery('textarea.tinymce').length) {
      tinymce.init({
        selector: "textarea.tinymce",
        language: 'pl',
        plugins: ["code", "autolink", "link", "image", "lists", "advlist", "hr", "nonbreaking", "autoresize", "searchreplace", "wordcount", "visualblocks", "visualchars", "fullscreen", "insertdatetime", "paste", "table"],
        autoresize_max_height: 500,
        toolbar: "insertfile undo redo | bold italic underline strikethrough removeformat | link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | styleselect fontsizeselect",
        fontsize_formats: "0.5rem 0.6rem 0.7rem 0.8rem 0.9rem 1.0rem 1.1rem 1.2rem 1.3rem 1.4rem 1.5rem 1.6rem 1.7rem 1.8rem 1.9rem 2.0rem 2.1rem 2.2rem 2.3rem 2.4rem 2.5rem 2.6rem 2.7rem 2.8rem 2.9rem 3.0rem",
        convert_urls: false,
        relative_urls: false,
        entity_encoding: "raw"
      });
    }
  }
  //--------------------------------------------------------
  // dla wszystkich elementów WE - dodatki
  //--------------------------------------------------------
  jQuery(".we-over-comments").on("mouseover", function () { var CommentsTxt = jQuery(this).attr("data-we-over-comments"); if (CommentsTxt && parent.FrameComments) parent.FrameComments(CommentsTxt); });
  // na potrzeby hurtowego zaznaczania kolumn checkbox oraz radio
  jQuery(".we-checkbox-controler-clickallchecked").on("click", function () { var dataclass = jQuery(this).attr("data-class-controler"); jQuery('input.' + dataclass + ':checkbox').prop('checked', this.checked); });
  jQuery(".we-radio-controler-setallvalue").on("click", function () { var dataclass = jQuery(this).attr("data-class-controler"); jQuery('input.' + dataclass + ':radio').filter('[value="' + jQuery(this).val() + '"]').prop('checked', true); });
  // na potrzeby zaznaczenia zakładki która jest nie zapisana
  jQuery(".we-form-control").on("change keyup paste", function () { jQuery("button.nav-link.active").addClass('we-change-data'); });
  // na potrzeby wyszukiwarki by enter ja wywołał
  jQuery(".we-form-search-field-text").on("keypress", function (e) { if (e.key == "Enter") { jQuery("#Formularz").trigger("submit"); } });
  // do weryfikacji wstecz
  jQuery(".we-button-click-submit-hidden").on("click", function () { jQuery("#" + jQuery(this).attr("id") + "hidden").val(1); jQuery("#Formularz").trigger("submit"); });
  jQuery(".we-change-form-submit-id").on("change", function () { var FormName = jQuery(this).attr("data-we-change-form-submit-id"); if (FormName) jQuery("#" + FormName).trigger("submit"); });
  jQuery(".we-click-form-submit-id").on("click", function () { var FormName = jQuery(this).attr("data-we-click-form-submit-id"); if (FormName) jQuery("#" + FormName).trigger("submit"); });



  //--------------------------------------
  // dla map
  //--------------------------------------
  jQuery('.we-mapcreator').each(function () {
    //------------------------------------
    // Na potrzeby MAP
    //------------------------------------
    var mapLayers = []; // Zasobnik na warstwy mapy
    var mapControls = []; // zasobnik na kontrolki dla mapy
    var mapOverlays = []; //  zasobnik na dodatkowe elelemnty, warstwy dla mapy
    var mapSelectedFeaturesLayersKmlChart = []; // Obsługa kliknietych elementów
    var mapFitExtent = null; // jeśli mapa ma sie samodzielnie dostosowac do elementów 
    //var mapScaleLineUnitsList = ['metric', 'degrees', 'imperial', 'us', 'nautical'];
    var id = jQuery(this).attr('id');
    var idMap = parseInt(jQuery(this).attr('data-idmap'));
    var mapDefaultLon = parseFloat(jQuery(this).attr('data-default-lon'));
    var mapDefaultLat = parseFloat(jQuery(this).attr('data-default-lat'));
    var mapDefaultZoom = parseInt(jQuery(this).attr('data-default-zoom'));
    var addMarker = parseInt(jQuery(this).attr('data-addmarker'));
    var addMarkerImage = jQuery(this).attr('data-addmarker-image');
    var addMarkerAnchorXUnits = jQuery(this).attr('data-addmarker-icon-anchor-x-units');
    var addMarkerAnchorYUnits = jQuery(this).attr('data-addmarker-icon-anchor-y-units');
    var addMarkerIconAnchorX = parseFloat(jQuery(this).attr('data-addmarker-icon-anchor-x'));
    var addMarkerIconAnchorY = parseFloat(jQuery(this).attr('data-addmarker-icon-anchor-y'));
    var addMarkerIconOpacity = parseFloat(jQuery(this).attr('data-addmarker-icon-opacity'));


    // przechwytywanie współrzędnych
    var elemFormGetLatLon = (jQuery(this).attr('data-get-latlon') ? true : false);
    var elemFormSetLon = jQuery(this).attr('data-form-lon');
    var elemFormSetLat = jQuery(this).attr('data-form-lat');
    var elemFormSetZoom = jQuery(this).attr('data-form-zoom');
    var elemFormSetInfo = jQuery(this).attr('data-form-info');
    var elemFormSetPostcode = jQuery(this).attr('data-form-postcode');
    var elemFormSetCity = jQuery(this).attr('data-form-city');
    var elemFormSetVillage = jQuery(this).attr('data-form-village');
    var elemFormSetCountry = jQuery(this).attr('data-form-country');
    var elemFormSetRoad = jQuery(this).attr('data-form-road');
    var elemFormSetHouseNumber = jQuery(this).attr('data-form-house-number');
    var dataControlsOverviewMap = ((jQuery(this).attr('data-controls-overviewmap') == "1" || jQuery(this).attr('data-controls-overviewmap') == "true") ? true : false);
    var dataControlsOverviewMapDisable = ((jQuery(this).attr('data-controls-overviewmap') == "0" || jQuery(this).attr('data-controls-overviewmap') == "false") ? true : false);
    var defaultInteractionsOnFocus = ((jQuery(this).attr('data-default-interactions-onfocusonly') == "1" || jQuery(this).attr('data-default-interactions-onfocusonly') == "true") ? true : false);
    var defaultInteractionsOnFocusDisable = ((jQuery(this).attr('data-default-interactions-onfocusonly') == "0" || jQuery(this).attr('data-default-interactions-onfocusonly') == "false") ? true : false);

    // jesli podano obiekt w formacie GeoJson do publikacji
    var dataGeojsonUrl = jQuery(this).attr('data-geojson-url');
    var dataGeojsonFit = ((jQuery(this).attr('data-geojson-fit') == "1" || jQuery(this).attr('data-geojson-fit') == "true") ? true : false);
    var dataGeojsonObject = jQuery(this).attr('data-geojson-object');

    if (typeof dataGeojsonObject !== typeof undefined && dataGeojsonObject !== false) dataGeojsonObject = JSON.parse(decodeURIComponent(dataGeojsonObject)); else dataGeojsonObject = '';
    //console.log(dataGeojsonObject);
    var dataWktFit = ((jQuery(this).attr('data-wkt-fit') == "1" || jQuery(this).attr('data-wkt-fit') == "true") ? true : false);
    var dataWktPadding = 30; if (jQuery(this).attr('data-wkt-padding')) dataWktPadding = jQuery(this).attr('data-wkt-padding');
    var dataWkt = jQuery(this).attr('data-wkt-object'); if (typeof dataWkt !== typeof undefined && dataWkt !== false) dataWkt = decodeURIComponent(dataWkt); else dataWkt = '';

    // jesli konieczne jest dodanie pliku KML
    var dataKmlUrl = jQuery(this).attr('data-kml-url');
    var dataKmlFit = ((jQuery(this).attr('data-kml-fit') == "1" || jQuery(this).attr('data-kml-fit') == "true") ? true : false);

    //console.log(dataKmlUrl);

    var dataSearchOn = ((jQuery(this).attr('data-search-on') == "1" || jQuery(this).attr('data-search-on') == "true") ? true : false);

    //console.log(dataSearchOn);

    var divIdMap = 'we-map-id-' + id;

    //------------------------------------
    // Wczytujemy definicje mapy lub konfiguracje domyslną
    //------------------------------------
    var configMap = ((idMap && (typeof weMapCreatorConfig !== 'undefined') && weMapCreatorConfig && weMapCreatorConfig.hasOwnProperty(idMap)) ? weMapCreatorConfig[idMap] : {
      config: {
        defaulthref: '/we/ext/we/',
        defaultMap: {
          Lon: 0,
          Lat: 0,
          Zoom: 12
        },
        defaultInteractions: {
          OnFocusOnly: true,
        },
        defaultControls: {
          OverviewMap: false,
          ZoomSlider: false,
          ScaleLine: false,
          MousePosition: false,
          ContextMenu: false
        }
      },
      groups: [
        {
          openpanel: false,
          id: 1,
          label: 'Podkład',
          type: 'radio',
          addempty: false,
          addemptylabel: '',
          addmenu: 0,
          layers: [
            { provider: 'osm', layerid: 10, opacity: 1, visible: true, selectable: false, addmenu: false, label: 'Open Street Maps', scheme: 'osm' }
          ]
        }
      ]
    });
    //------------------------------------
    // Wczytujemy konfigurację mapy do zmiennej
    // Sterowanie mapą za pomoca myszy / kółka
    //------------------------------------
    var configConfigMap = (configMap.hasOwnProperty('config') ? configMap['config'] : {
      defaulthref: '/we/ext/we/',
      defaultMap: {
        Lon: 0,
        Lat: 0,
        Zoom: 12
      },
      defaultInteractions: {
        OnFocusOnly: true,
      },
      defaultControls: {
        OverviewMap: false,
        ZoomSlider: false,
        ScaleLine: false,
        MousePosition: false,
        ContextMenu: false
      }
    });
    var mapDefaultHref = (configConfigMap.hasOwnProperty('defaulthref') ? configConfigMap['defaulthref'] : '/we/ext/we/');
    // map Interactions
    var configMapDefaultInteractions = (configConfigMap.hasOwnProperty('defaultInteractions') ? configConfigMap['defaultInteractions'] : { OnFocusOnly: true });
    var mapInteractionsOnFocusOnly = (configMapDefaultInteractions.hasOwnProperty('OnFocusOnly') ? configMapDefaultInteractions['OnFocusOnly'] : true);
    if (defaultInteractionsOnFocus) mapInteractionsOnFocusOnly = true;
    if (defaultInteractionsOnFocusDisable) mapInteractionsOnFocusOnly = false;
    // map Controls
    var configMapDefaultControls = (configConfigMap.hasOwnProperty('defaultControls') ? configConfigMap['defaultControls'] : { OverviewMap: false, ZoomSlider: false, ScaleLine: false, MousePosition: false, ContextMenu: false });
    var controlsOverviewMap = (configMapDefaultControls.hasOwnProperty('OverviewMap') ? configMapDefaultControls['OverviewMap'] : false);
    var controlsZoomSlider = (configMapDefaultControls.hasOwnProperty('ZoomSlider') ? configMapDefaultControls['ZoomSlider'] : false);
    var controlsScaleLine = (configMapDefaultControls.hasOwnProperty('ScaleLine') ? configMapDefaultControls['ScaleLine'] : false);
    var controlsMousePosition = (configMapDefaultControls.hasOwnProperty('MousePosition') ? configMapDefaultControls['MousePosition'] : false);
    var controlsContextMenu = (configMapDefaultControls.hasOwnProperty('ContextMenu') ? configMapDefaultControls['ContextMenu'] : false);
    // połozenie mapy
    var configMapDefaultMap = (configConfigMap.hasOwnProperty('defaultMap') ? configConfigMap['defaultMap'] : { Lon: 0, Lat: 0, Zoom: 12 });
    mapDefaultLon = (mapDefaultLon ? mapDefaultLon : (configMapDefaultMap.hasOwnProperty('Lon') ? configMapDefaultMap['Lon'] : 0));
    mapDefaultLat = (mapDefaultLat ? mapDefaultLat : (configMapDefaultMap.hasOwnProperty('Lat') ? configMapDefaultMap['Lat'] : 0));
    mapDefaultZoom = (mapDefaultZoom ? mapDefaultZoom : (configMapDefaultMap.hasOwnProperty('Zoom') ? configMapDefaultMap['Zoom'] : 15));
    //------------------------------------
    // Content menu MAPA
    //------------------------------------
    var ContextMenuAllItems = [
      { text: 'Przybliż mapę', icon: mapDefaultHref + 'image/maps-marker/context-menu-zoom-in.png', callback: function (obj, map) { const view = map.getView(); view.animate({ zoom: (view.getZoom() + 1), duration: 700, center: obj.coordinate }); } },
      { text: 'Oddal mapę', icon: mapDefaultHref + 'image/maps-marker/context-menu-zoom-out.png', callback: function (obj, map) { const view = map.getView(); view.animate({ zoom: (view.getZoom() - 1), duration: 700, center: obj.coordinate }); } },
      { text: 'Wycentruj mapę', icon: mapDefaultHref + 'image/maps-marker/context-menu-center.png', callback: function (obj) { view.animate({ duration: 700, /* easing: function (t) { return Math.pow(2, -10 * t) * Math.sin((t - 0.075) * (2 * Math.PI) / 0.3) + 1; }, */  center: obj.coordinate }); } }
      /* , '-',
      {
       text: 'Pokaż współrzędne',
       classname: 'some-style-class', // you can add this icon with a CSS class
       // instead of `icon` property (see next line)
       icon: './image/maps-marker/context-menu-marker.png',  // this can be relative or absolute
       callback: function (obj, map) {
         var lonlat = toLonLat(obj.coordinate);
         var hdms = toStringHDMS(lonlat);
         popupLonLatTitle.innerHTML = 'Wskazane miejsce:';
         popupLonLatContent.innerHTML = '<p class="m-0 p-0">' + hdms + '<br>lon: ' + lonlat[0] + '<br>lat: ' + lonlat[1] + '</p>';
         popupLonLatOverlay.setPosition(obj.coordinate);
       }
     },'-', {
       text: 'Dodaj zgłoszenie',
       classname: 'some-style-class', // you can add this icon with a CSS class
       // instead of `icon` property (see next line)
       icon: 'maps-marker/marker.png',  // this can be relative or absolute
       callback: function () { }
     }, '-', {
       text: 'Zmień podkład mapy',
       items: [
         { text: 'OpenStreetMap', icon: 'maps-marker/view_list.png', callback: function () { LayerSelect.value = 'osm::osm'; onChangeLayersMap(); } },
         { text: 'Stamen: Terrain', icon: 'maps-marker/view_list.png', callback: function () { LayerSelect.value = 'stamen::terrain'; onChangeLayersMap(); } },
         { text: 'Stamen: Toner', icon: 'maps-marker/view_list.png', callback: function () { LayerSelect.value = 'stamen::toner'; onChangeLayersMap(); } },
         { text: 'Stamen: Watercolor', icon: 'maps-marker/view_list.png', callback: function () { LayerSelect.value = 'stamen::watercolor'; onChangeLayersMap(); } }
       ]
     },'-', {
       text: 'Zmień jednostkę skali',
       items: [
         { text: 'metry', callback: function () { scaleLineControl.setUnits('metric'); } },
         { text: 'stopnie', callback: function () { scaleLineControl.setUnits('degrees'); } },
         { text: 'imperial', callback: function () { scaleLineControl.setUnits('imperial'); } },
         { text: 'us', callback: function () { scaleLineControl.setUnits('us'); } },
         { text: 'nautical', callback: function () { scaleLineControl.setUnits('nautical'); } }
       ]
     }
   */
    ];
    //------------------------------------
    // przeglądamy wszystkie grupy - ładujemy warstwy z danej grupy
    //------------------------------------
    if (configMap.hasOwnProperty('groups')) {
      for (var g = 0; g < configMap['groups'].length; g++) {
        var groupLayer = configMap['groups'][g];
        if (groupLayer.hasOwnProperty('layers')) {
          for (var i = 0; i < groupLayer['layers'].length; i++) {
            var layerDesc = groupLayer['layers'][i];
            var mapLayersObject = mapLoadLayers(layerDesc);
            if (mapLayersObject) {
              mapLayersObject.set('id', id);
              mapLayersObject.set('idmap', idMap);
              mapLayersObject.set('grouptype', groupLayer.type);
              mapLayersObject.set('groupid', groupLayer.id);
              mapLayersObject.set('provider', layerDesc.provider);
              mapLayersObject.set('layerid', layerDesc.id);
              mapLayersObject.set('selectable', layerDesc.selectable);
              mapLayers.unshift(mapLayersObject);
            }
          }
        }
      }
    }
    //------------------------------------
    // przeglądamy wszystkie grupy - ładujemy warstwy z danej grupy
    //------------------------------------
    if (configMap.hasOwnProperty('layers')) {
      for (var i = 0; i < configMap['layers'].length; i++) {
        var layerDesc = configMap['layers'][i];
        var mapLayersObject = mapLoadLayers(layerDesc);
        if (mapLayersObject) {
          mapLayersObject.set('id', id);
          mapLayersObject.set('idmap', idMap);
          mapLayersObject.set('grouptype', 'checkbox');
          mapLayersObject.set('groupid', 1);
          mapLayersObject.set('provider', layerDesc.provider);
          mapLayersObject.set('layerid', layerDesc.id);
          mapLayersObject.set('selectable', layerDesc.selectable);
          mapLayers.unshift(mapLayersObject);
        }
      }
    }
    //------------------------------------
    // gdy jest cokolwiek do wyświetlenia bo są zdefiniowane elementy mapy - uruchamiamy aplikację do generowania mapy
    //------------------------------------
    if (mapLayers.length > 0) {
      jQuery(this).append('<div class="we-map-content"><div id="' + divIdMap + '" class="we-map"></div></div>');

      if (dataSearchOn) jQuery(this).append('<div id="' + divIdMap + '-search" class="we-map-search-position"><div class="input-group input-group-sm">'
        + '<input type="text" id="' + divIdMap + '-search-query" class="form-control" placeholder="szukaj miejsca na mapie" aria-label="szukaj" aria-describedby="wyszukiwarka">'
        + '<div class="input-group-prepend"><div class="input-group-text"><input type="checkbox" id="' + divIdMap + '-search-query-type" aria-label="Wyniki jako punkty" title="Pokaż wyniki jako punkty na mapie"></div></div>'
        + ' <div class="input-group-append"><button class="btn btn-sm btn-secondary we-map-search-btn" data-search-query-id="' + divIdMap + '-search-query" data-idmap="' + idMap + '" type="button">szukaj</button></div>'
        + '</div></div>');

      jQuery(this).append('<div id="' + divIdMap + '-mouse-position" class="we-map-mouse-position"></div>');
      jQuery(this).append('<div id="' + divIdMap + '-popup" class="ol-popup"><div id="' + divIdMap + '-popup-closer" class="ol-popup-closer"></div><div id="' + divIdMap + '-popup-title" class="ol-popup-title"></div><div id="' + divIdMap + '-popup-content" class="ol-popup-content"></div></div>');
      // zasobnik na acordion
      var menuHtmlContent = [];
      // przeglądamy wszystkie grupy
      if (configMap.hasOwnProperty('groups')) {
        for (var g = 0; g < configMap['groups'].length; g++) {
          var groupLayer = configMap['groups'][g];
          var groupLayerId = (groupLayer.hasOwnProperty('id') ? groupLayer['id'] : '');
          var groupLayerType = (groupLayer.hasOwnProperty('type') ? groupLayer['type'] : '');
          var groupLayerAddEmpty = (groupLayer.hasOwnProperty('addempty') ? groupLayer['addempty'] : false);
          var groupLayerAddEmptyLabel = (groupLayer.hasOwnProperty('addemptylabel') ? ((groupLayer['addemptylabel'] == '') ? 'brak / pusty' : groupLayer['addemptylabel']) : 'brak / pusty');
          var groupLayerMenuLabel = (groupLayer.hasOwnProperty('label') ? groupLayer['label'] : 'Lista elementów');
          var groupLayerMenuAdd = (groupLayer.hasOwnProperty('addmenu') ? groupLayer['addmenu'] : false);
          var showPanel = ((groupLayer.hasOwnProperty('openpanel') ? groupLayer['openpanel'] : false) ? 'show' : '');
          // tylko te warstwy które mogą być publiczne
          if (groupLayerMenuAdd) {
            var menuHtmlGroupContent = [];
            if (groupLayerType && groupLayer.hasOwnProperty('layers')) {
              for (var i = 0; i < groupLayer['layers'].length; i++) {
                var layerDesc = groupLayer['layers'][i];
                if (layerDesc.hasOwnProperty('addmenu') && layerDesc['addmenu']) {
                  var menuLabel = ((layerDesc.hasOwnProperty('label')) ? layerDesc.label : 'brak nazwy');
                  if (groupLayerType == 'radio') menuHtmlGroupContent.push('<div class="form-check"><input class="form-check-input we-map-layer" type="radio" data-group-type="' + groupLayerType + '" data-group-id="' + groupLayerId + '" data-id="' + id + '" data-idmap="' + idMap + '" data-layer-id="' + layerDesc.id + '" name="' + divIdMap + '-' + groupLayerId + '-layer" id="' + divIdMap + '-' + groupLayerId + '-' + layerDesc.id + '-layer" value="' + layerDesc.id + '"' + ((layerDesc.visible == true) ? ' checked' : '') + '><label class="form-check-label" for="' + divIdMap + '-' + groupLayerId + '-' + layerDesc.id + '-layer">' + menuLabel + '</label></div>');
                  else if (groupLayerType == 'checkbox') menuHtmlGroupContent.push('<div class="form-check"><input class="form-check-input we-map-layer" type="checkbox" data-group-type="' + groupLayerType + '" data-group-id="' + groupLayerId + '"  data-id="' + id + '" data-idmap="' + idMap + '"  data-layer-id="' + layerDesc.id + '" name="' + divIdMap + '-' + groupLayerId + '-layer-id-' + layerDesc.id + '" id="' + divIdMap + '-' + groupLayerId + '-layer-id-' + layerDesc.id + '" value="1"' + ((layerDesc.visible == true) ? ' checked' : '') + '><label class="form-check-label" for="' + divIdMap + '-' + groupLayerId + '-layer-id-' + layerDesc.id + '">' + menuLabel + '</label></div>');
                }
              }
            }
            // jezeli warstwy zawierają cokolwiek do publikacji - pokazujemy
            if (menuHtmlGroupContent.length > 0) {
              menuHtmlContent.push('<div class="card rounded-0 border-0"><div class="card-header p-0 m-0" id="' + divIdMap + '-heading' + g + '"><button class="btn btn-link rounded-0" type="button" data-toggle="collapse" data-target="#' + divIdMap + '-collapse' + g + '" aria-expanded="true" aria-controls="' + divIdMap + '-collapse' + g + '">' + groupLayerMenuLabel + '</button></div><div id="' + divIdMap + '-collapse' + g + '" class="collapse ' + showPanel + '" aria-labelledby="' + divIdMap + '-heading' + g + '" data-parent="#' + divIdMap + '-accordion"><div class="card-body py-2">');
              if (groupLayerAddEmpty) menuHtmlContent.push('<div class="form-check"><input class="form-check-input we-map-layer" type="radio" data-group-type="' + groupLayerType + '" data-group-id="' + groupLayerId + '"  data-id="' + id + '" data-idmap="' + idMap + '"  data-layer-id="0" name="' + divIdMap + '-' + groupLayerId + '-layer" id="' + divIdMap + '-' + groupLayerId + '-0-layer" value="0"><label class="form-check-label" for="' + divIdMap + '-' + groupLayerId + '-0-layer">' + groupLayerAddEmptyLabel + '</label></div>');
              menuHtmlContent.push(menuHtmlGroupContent.join(''));
              menuHtmlContent.push('</div></div></div>');
            }
          }
        }
        // dodajemy tresć do menu jeśli jest co dodać
        if (menuHtmlContent.length > 0) jQuery(this).append('<div id="' + divIdMap + '-menu" class="we-map-menu border"><div id="' + divIdMap + '-accordion" class="accordion we-map-accordion">' + menuHtmlContent.join('') + '</div>');
      }

      //------------------------------------
      // Okno chmurki
      //------------------------------------
      var popup = document.getElementById(divIdMap + '-popup');
      var popupTitle = document.getElementById(divIdMap + '-popup-title');
      var popupContent = document.getElementById(divIdMap + '-popup-content');
      var popupCloser = document.getElementById(divIdMap + '-popup-closer');
      var popupOverlay = new Overlay({ element: popup, autoPan: true, autoPanAnimation: { duration: 250 } });
      popupCloser.onclick = function () { popupTitle.innerHTML = ''; popupContent.innerHTML = ''; popupOverlay.setPosition(undefined); popupCloser.blur(); return false; };

      //------------------------------------
      // mamy dodać marker ustawiony zewnętrznie
      //------------------------------------

      addMarkerImage = addMarkerImage ? addMarkerImage : mapDefaultHref + 'image/maps-marker/map-marker-default.png';
      addMarkerIconAnchorX = addMarkerIconAnchorX ? addMarkerIconAnchorX : 0.5;
      addMarkerIconAnchorY = addMarkerIconAnchorY ? addMarkerIconAnchorY : 1;
      addMarkerAnchorXUnits = addMarkerAnchorXUnits ? addMarkerAnchorXUnits : 'fraction';
      addMarkerAnchorYUnits = addMarkerAnchorYUnits ? addMarkerAnchorYUnits : 'fraction';
      addMarkerIconOpacity = addMarkerIconOpacity ? addMarkerIconOpacity : 1;

      var imageStyleMarker = new Icon({
        anchor: [addMarkerIconAnchorX, addMarkerIconAnchorY],
        anchorXUnits: addMarkerAnchorXUnits,
        anchorYUnits: addMarkerAnchorYUnits,
        src: addMarkerImage
      });


      if (addMarker) {

        var addMarkerObject = new VectorLayer({
          source: new VectorSource({
            features: [
              new Feature({
                geometry: new Point(fromLonLat([mapDefaultLon, mapDefaultLat]))
                //name: 'marker',
                //description: 'informacje o tym miejscu'
              })
            ],
            attributions: '&copy; <a href="http://d17.pl/" target="_blank">d17.pl</a>'
          }),
          style: new Style({
            image: imageStyleMarker
          }),
          opacity: addMarkerIconOpacity
        });
        addMarkerObject.set('selectable', true);
        addMarkerObject.set('provider', 'marker');
        mapLayers.push(addMarkerObject);
      }

      /*
      var imageStyleFunction = new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({ color: 'blue', width: 3 }),
      });
      */

      var stylesGetFunction = {
        'Point': new Style({
          image: imageStyleMarker,
        }),
        'LineString': new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 2,
          }),
        }),
        'MultiLineString': new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 2,
          }),
        }),
        'MultiPoint': new Style({
          image: imageStyleMarker,
        }),
        'MultiPolygon': new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        }),
        'Polygon': new Style({
          stroke: new Stroke({
            color: 'blue',
            //lineDash: [4],
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        }),
        'GeometryCollection': new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 2,
          }),
          fill: new Fill({
            color: 'magenta',
          }),
          image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
              color: 'magenta',
            }),
          }),
        }),
        'Circle': new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(255,0,0,0.2)',
          }),
        }),
      };

      var styleGetFunction = function (feature) {
        return stylesGetFunction[feature.getGeometry().getType()];
      };

      //------------------------------------
      // mamy dodać obiekty na mapie jako format WTK
      //------------------------------------
      if (dataWkt) {
        //var koreanProj = ol.proj.get('EPSG:2177');

        //proj4.defs("EPSG:2177","+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

        var formatWKT = new WKT();
        var featureWKT = formatWKT.readFeature(dataWkt, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });


        var vectorWKTSource = new VectorSource({
          features: [featureWKT],
          attributions: '&copy; <a href="http://d17.pl/" target="_blank">d17.pl</a>'
        });

        var addaddWKTObject = new VectorLayer({
          source: vectorWKTSource,
          style: styleGetFunction,
          visible: 1,
          opacity: 1
        });

        addaddWKTObject.set('selectable', true);
        addaddWKTObject.set('provider', 'dataWKT');
        mapLayers.push(addaddWKTObject);

        if (dataWktFit) {
          var featuresWKT = vectorWKTSource.getFeatures();
          if(featuresWKT)
          {
            mapFitExtent = createEmpty(); // mapFitExtent - jeśli nie jest pusty - automatycznie dopasuje sie mapa
            if (featuresWKT.length) featuresWKT.forEach(function (feature) { var tmpGeometry = feature.getGeometry(); if(tmpGeometry) extend(mapFitExtent, tmpGeometry.getExtent()); });
          }
        }
      }

      //------------------------------------
      // mamy dodać obiekty na mapie jako geoJson - przekazane do wizualizacji
      //------------------------------------
      if (dataGeojsonObject) {

        var vectorGeojsonSource = new VectorSource({
          features: new GeoJSON().readFeatures(dataGeojsonObject, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }),
          attributions: '&copy; <a href="http://d17.pl/" target="_blank">d17.pl</a>'
        });

        var addGeojsonObject = new VectorLayer({
          source: vectorGeojsonSource,
          style: styleGetFunction,
          visible: 1,
          opacity: 1
        });

        addGeojsonObject.set('selectable', true);
        addGeojsonObject.set('provider', 'dataGeojson');
        mapLayers.push(addGeojsonObject);

        // jeśli włączono scalowanie
        if (dataGeojsonFit) {
          var featuresGeojson = vectorGeojsonSource.getFeatures();
          if (featuresGeojson.length) {
            mapFitExtent = createEmpty();
            featuresGeojson.forEach(function (feature) { var tmpGeometry = feature.getGeometry(); if(tmpGeometry) extend(mapFitExtent, tmpGeometry.getExtent()); });
          }
        }
      }

      //------------------------------------
      // mamy dodać obiekty na mapie jako geoJson - przekazane w url
      //------------------------------------
      if (dataGeojsonUrl) {

        var addGeojsonObject = new VectorLayer({
          source: new VectorSource({
            url: dataGeojsonUrl,
            format: new GeoJSON({ dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }),
            attributions: '&copy; <a href="http://d17.pl/" target="_blank">d17.pl</a>'
          }),
          style: styleGetFunction,
          visible: 1,
          opacity: 1
        });

        addGeojsonObject.set('selectable', true);
        addGeojsonObject.set('provider', 'dataGeojson');
        mapLayers.push(addGeojsonObject);

        popupTitle.innerHTML = 'Czekaj...';
        popupContent.innerHTML = 'Trwa pobieranie danych...';
        popupOverlay.setPosition(fromLonLat([mapDefaultLon, mapDefaultLat]));

        // jeśli włączono scalowanie

        addGeojsonObject.getSource().on('change', function (evt) {
          var source = evt.target;
          if (source.getState() === 'ready') {
            if (dataGeojsonFit) {
              var featureGeojson = source.getFeatures();
              if(featureGeojson)
              {
                mapFitExtent = createEmpty();
                featureGeojson.forEach(function (feature) { var tmpGeometry = feature.getGeometry(); if(tmpGeometry) extend(mapFitExtent, tmpGeometry.getExtent()); });
                map.getView().fit(mapFitExtent, { padding: [dataWktPadding, dataWktPadding, dataWktPadding, dataWktPadding] });
              }
            }
            popupOverlay.setPosition(undefined);
          }
        });

      }

      //------------------------------------
      // mamy dodać obiekty na mapie jako KML - plik url
      //------------------------------------
      if (dataKmlUrl) {
        var addKmlFileObject = new VectorLayer({
          source: new VectorSource({
            url: dataKmlUrl,
            projection: 'EPSG:3857',
            format: new KML({
              extractStyles: true,
              extractAttributes: true,
              maxDepth: 10
            })
          }),
          visible: 1,
          opacity: 1
        });

        addKmlFileObject.set('selectable', true);
        addKmlFileObject.set('provider', 'kml');
        mapLayers.push(addKmlFileObject);

        popupTitle.innerHTML = 'Czekaj...';
        popupContent.innerHTML = 'Trwa pobieranie danych...';
        popupOverlay.setPosition(fromLonLat([mapDefaultLon, mapDefaultLat]));

        // jeśli włączono dopasowanie do mapy - czekamy na załadowanie danych i ustawiamy mapę.

        addKmlFileObject.getSource().on('change', function (evt) {
          var source = evt.target;
          if (source.getState() === 'ready') {
            if (dataKmlFit) {
              var featureGeojson = source.getFeatures();
              if(featureGeojson)
              {
                mapFitExtent = createEmpty();
                featureGeojson.forEach(function (feature) { var tmpGeometry = feature.getGeometry(); if(tmpGeometry) extend(mapFitExtent, tmpGeometry.getExtent()); });
                map.getView().fit(mapFitExtent, { padding: [dataWktPadding, dataWktPadding, dataWktPadding, dataWktPadding] });
              }
            }
            popupOverlay.setPosition(undefined);
          }
        });

      }


      mapOverlays.push(popupOverlay);


      // zawsze pokazujemy etykietę attribution
      mapControls.push(new Attribution({ collapsible: false }));
      if (dataControlsOverviewMap) controlsOverviewMap = true;
      if (dataControlsOverviewMapDisable) controlsOverviewMap = false;
      if (controlsOverviewMap) mapControls.push(new OverviewMap({ layers: [new TileLayer({ source: new OSM() })] }));
      if (controlsZoomSlider) mapControls.push(new ZoomSlider());
      if (controlsScaleLine) mapControls.push(new ScaleLine());
      if (controlsMousePosition) mapControls.push(new MousePosition({ coordinateFormat: createStringXY(8), projection: 'EPSG:4326', className: 'custom-mouse-position', target: document.getElementById(divIdMap + '-mouse-position'), undefinedHTML: '' }));
      if (controlsContextMenu) mapControls.push(new ContextMenu({ width: 210, defaultItems: false, items: ContextMenuAllItems }));
      //mapControls.push(new FullScreen({source: divIdMap}));
      //------------------------------------
      // Marker na mapie do klikania i przechwytywania współrzednych
      //------------------------------------
      var elemFeatureGetLatLon = new Feature();
      elemFeatureGetLatLon.setStyle(new Style({ image: new Icon({ anchor: [0.5, 1], src: mapDefaultHref + 'image/maps-marker/map-marker-getlatlon.png' }) }));
      elemFeatureGetLatLon.setGeometry(fromLonLat([mapDefaultLon, mapDefaultLat]) ? new Point(fromLonLat([mapDefaultLon, mapDefaultLat])) : null);
      //------------------------------------
      // Generujemy mapę
      //------------------------------------

      var view = new View({ center: fromLonLat([mapDefaultLon, mapDefaultLat]), zoom: mapDefaultZoom });
      var map = new Map({
        interactions: defaultInteractions({ onFocusOnly: mapInteractionsOnFocusOnly }), //, constrainResolution: true
        controls: defaultControls({ attribution: false }).extend(mapControls),
        layers: mapLayers,
        overlays: mapOverlays,
        loadTilesWhileInteracting: true,
        target: divIdMap,
        view: view
      });

      // zmieniamy, dopasowujemy mape jesli przekazano parametry.
      if (mapFitExtent) map.getView().fit(mapFitExtent, { padding: [dataWktPadding, dataWktPadding, dataWktPadding, dataWktPadding] });  //padding: [topPadding, rightPadding, bottomPadding, leftPadding]; 

      //------------------------------------
      // Zmieniono skalę mapy
      //------------------------------------
      map.getView().on("change:resolution", function (e) {
        //------------------------------------
        // jeżeli włączono przechwytywanie mapy
        //------------------------------------
        if (elemFormGetLatLon) {
          if (elemFormSetZoom) jQuery('#' + elemFormSetZoom).val(map.getView().getZoom().toFixed(2));
        }
        //console.log(map.getView().getZoom().toFixed(2));
      });
      //------------------------------------
      // Kliknieto w mapę
      //------------------------------------
      map.on('click', function (event) {
        //------------------------------------
        // usuwamy wczesniej aktywne punkty dla warstwy KmlChart o ile zostały utworzone
        //------------------------------------
        if (mapSelectedFeaturesLayersKmlChart.length > 0) {
          for (var i = 0; i < mapSelectedFeaturesLayersKmlChart.length; i++) mapSelectedFeaturesLayersKmlChart[i].setStyle(null);
          mapSelectedFeaturesLayersKmlChart = [];
        }
        //------------------------------------
        // analizujemy całą przestrzeń kliknietą
        //------------------------------------
        map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
          // czyścimy zawartość popup gdyż treści się mają podmienić
          popupTitle.innerHTML = '';
          popupContent.innerHTML = '';
          popupOverlay.setPosition(undefined);
          //------------------------------------
          // Tylko dopuszczone do zaznaczania
          //------------------------------------
          if (layer && layer.get('selectable') == true) {
            //------------------------------------
            // Zmiana stylów dla wykresów klm-chart
            //------------------------------------
            if (layer.get('provider') == 'dataGeojson') {
              var featureProperties = feature.getProperties();
              if (featureProperties) {
                //console.log(featureProperties);
                //Object.entries(featureProperties).forEach(([key, value]) => console.log(`${key}: ${value}`));

                var popupContentArray = [];
                Object.entries(featureProperties).forEach(function ([key, value]) {

                  if (typeof value !== 'object') {
                    //console.log(key+': '+value +' '+typeof value);
                    popupContentArray.push(key + ': ' + value);
                  }
                });

                if (popupContentArray.length > 0) {
                  popupTitle.innerHTML = 'szczegóły:';
                  popupContent.innerHTML = popupContentArray.join('<br>');

                  popupOverlay.setPosition(event.coordinate);
                }
              }
            }
            else if (layer.get('provider') == 'kml-chart') {
              var features = feature.get('features');
              if (features.length > 1) {
                var geometry = feature.getGeometry();
                var coordinate = geometry.getCoordinates();
                popupTitle.innerHTML = 'Grupa obiektów: ' + features.length;
                popupContent.innerHTML = 'W tym miejscu znajduje się więcej obiektów.<br>Przybliż mapę do tego miejsca aby zobaczyć szczegóły.';
                popupOverlay.setPosition(coordinate);
              }
              else if (features.length == 1) // kliknieto tylko w jeden obiekt
              {
                if (typeof features[0].get("name") !== 'undefined' || typeof features[0].get("description") !== 'undefined') {
                  var geometry = features[0].getGeometry();
                  var coordinate = geometry.getCoordinates();
                  if (typeof features[0].get("name") !== 'undefined') popupTitle.innerHTML = features[0].get("name");
                  if (typeof features[0].get("description") !== 'undefined') popupContent.innerHTML = features[0].get("description");
                  popupOverlay.setPosition(coordinate);
                }
                feature.setStyle(StyleChartKml(features[0], true));
                mapSelectedFeaturesLayersKmlChart.push(feature);
              }
            }
            //------------------------------------
            // prezentacja danych dla pozostałych warstw
            //------------------------------------            
            else if (layer.get('provider') == 'kml-marker-cluster' || layer.get('provider') == 'geojson-marker-cluster') {
              var originalFeature = feature.get('features');
              if (originalFeature.length > 1) {
                var geometry = feature.getGeometry();
                var coordinate = geometry.getCoordinates();
                popupTitle.innerHTML = 'Grupa obiektów: ' + originalFeature.length;
                popupContent.innerHTML = 'W tym miejscu znajduje się więcej obiektów.<br>Przybliż mapę do tego miejsca aby zobaczyć szczegóły.';
                popupOverlay.setPosition(coordinate);
              }
              else if (originalFeature.length == 1) {
                if (typeof originalFeature[0].get("name") !== 'undefined' || typeof originalFeature[0].get("description") !== 'undefined') {
                  var geometry = originalFeature[0].getGeometry();
                  var coordinate = geometry.getCoordinates();
                  if (typeof originalFeature[0].get("name") !== 'undefined') popupTitle.innerHTML = originalFeature[0].get("name");
                  if (typeof originalFeature[0].get("description") !== 'undefined') popupContent.innerHTML = originalFeature[0].get("description");
                  popupOverlay.setPosition(coordinate);
                }
              }
            }
            //------------------------------------
            // prezentacja danych dla pozostałych warstw
            //------------------------------------
            else {
              if (typeof feature.get("name") !== 'undefined' || typeof feature.get("description") !== 'undefined') {
                var geometry = feature.getGeometry();
                var coordinate = geometry.getCoordinates();
                if (typeof feature.get("name") !== 'undefined') popupTitle.innerHTML = feature.get("name");
                if (typeof feature.get("description") !== 'undefined') popupContent.innerHTML = feature.get("description");
                popupOverlay.setPosition(coordinate);
              }
            }
          }
        });
        //------------------------------------
        // jeżeli włączono przechwytywanie mapy
        //------------------------------------
        if (elemFormGetLatLon) {
          var hdms = toLonLat(event.coordinate);
          view.animate({ duration: 500, center: fromLonLat(hdms) });
          elemFeatureGetLatLon.setGeometry(event.coordinate ? new Point(event.coordinate) : null);
          if (elemFormSetLon) jQuery('#' + elemFormSetLon).val(hdms[0]).trigger('change');
          if (elemFormSetLat) jQuery('#' + elemFormSetLat).val(hdms[1]).trigger('change');
          if (elemFormSetZoom) jQuery('#' + elemFormSetZoom).val(map.getView().getZoom().toFixed(2)).trigger('change');
          mapGeocodingInfo(elemFormSetCountry, elemFormSetPostcode, elemFormSetCity, elemFormSetVillage, elemFormSetRoad, elemFormSetHouseNumber, elemFormSetInfo, hdms[0], hdms[1]);
        }
      });

      jQuery('button.we-map-search-btn').on("click", function () {

        var dataSearchQueryId = jQuery(this).attr('data-search-query-id');
        var dataIdMap = jQuery(this).attr('data-idmap');
        var QueryTxt = jQuery('#' + dataSearchQueryId) ? jQuery('#' + dataSearchQueryId).val() : '';
        var QueryPolygonGeojson = 1; if (jQuery('#' + dataSearchQueryId + '-type').is(':checked')) QueryPolygonGeojson = 0;

        if (idMap == dataIdMap) {
          // usuwamy wczesniejsze wyniki na warstwie searchLayer
          map.getLayers().getArray().forEach(function (layer, i) {
            if (layer.get('provider') == 'searchLayer') map.removeLayer(layer);
          });

          if (QueryTxt) {

            var vectorSearchSource = new VectorSource({
              url: 'https://nominatim.openstreetmap.org/search?format=geojson&polygon_geojson=' + QueryPolygonGeojson + '&q=' + QueryTxt,
              format: new GeoJSON(),
            });

            var vectorSearchLayer = new VectorLayer({
              source: vectorSearchSource,
              style: styleGetFunction,
              visible: 1,
              opacity: 1
            });

            vectorSearchLayer.set('selectable', true);
            vectorSearchLayer.set('provider', 'searchLayer');

            map.addLayer(vectorSearchLayer);

            vectorSearchSource.on('change', function (e) {
              if (vectorSearchSource.getState() == 'ready') {
                mapFitExtent = createEmpty();
                var featureGeojson = vectorSearchSource.getFeatures();
                if (featureGeojson.length) {
                  featureGeojson.forEach(function (feature) { extend(mapFitExtent, feature.getGeometry().getExtent()); });
                  map.getView().fit(mapFitExtent, { padding: [dataWktPadding, dataWktPadding, dataWktPadding, dataWktPadding], minZoom: 19 });
                }
              }
            });
          }
        }
      });

      //------------------------------------
      // gdy mapa się wygeneruje - ładujemy czujki na menu
      //------------------------------------
      map.once('postrender', function (event) {
        //------------------------------------
        // na potrzeby zmiany układu metrycznego dla linijki gdy w nią klikamy
        //------------------------------------
        //var ScaleLineUnits = document.getElementsByClassName('ol-scale-line');
        //for (var i = 0; i < ScaleLineUnits.length; i++) ScaleLineUnits[i].addEventListener("click", function () { ScaleLineUnitsNr++; scaleLineControl.setUnits(ScaleLineUnitsList[(ScaleLineUnitsNr % 5)]); });
        //------------------------------------
        // Interakcje dla zgrupowane w mwnu warstwy
        //------------------------------------
        jQuery('.we-map-layer').on("change", function () {
          var name = jQuery(this).attr('name');
          var dataId = jQuery(this).attr('data-id');
          var dataIdMap = jQuery(this).attr('data-idmap');
          var dataGroupId = jQuery(this).attr('data-group-id');
          var dataGroupType = jQuery(this).attr('data-group-type');
          var dataLayerId = jQuery(this).attr('data-layer-id');
          // dla obiektów radio tylko i wyłacznie w obrebie mapy
          if (dataGroupType == 'radio') { var LayerSelectValue = jQuery('input[type=radio][name=' + name + ']:checked').val(); for (var i = 0; i < mapLayers.length; i++) { if (mapLayers[i].get('id') == dataId && mapLayers[i].get('idmap') == dataIdMap && mapLayers[i].get('groupid') == dataGroupId) { var setVisible = ((mapLayers[i].get('layerid') == LayerSelectValue) ? true : false); mapLayers[i].setVisible(setVisible); } } }
          // dla obiektów checkbox tylko i wyłacznie w obrebie mapy i dla danego checkbox
          else if (dataGroupType == 'checkbox') { for (var i = 0; i < mapLayers.length; i++) { if (mapLayers[i].get('id') == dataId && mapLayers[i].get('idmap') == dataIdMap && mapLayers[i].get('groupid') == dataGroupId && mapLayers[i].get('layerid') == dataLayerId) { var setVisible = jQuery('input[type=checkbox][name=' + name + ']:checked').val() ? true : false; mapLayers[i].setVisible(setVisible); } } }
        });

        //------------------------------------
        // jeżeli włączono przechwytywanie mapy
        //------------------------------------
        if (elemFormGetLatLon) {
          //------------------------------------
          // dodajemy marker na mape
          //------------------------------------
          new VectorLayer({ map: map, source: new VectorSource({ features: [elemFeatureGetLatLon] }) });
        }
      });
    }
  });
  //------------------------------------
  // wyzwalamy dodatkowe funkcje
  //------------------------------------ 
  KnobResize();
  MenuResize();
});
////------------------------------------
//// Okno - pokaz współrzędne
////------------------------------------
//var popupLonLat = document.getElementById('popup-lonlat');
//var popupLonLatTitle = document.getElementById('popup-lonlat-title');
//var popupLonLatContent = document.getElementById('popup-lonlat-content');
//var popupLonLatCloser = document.getElementById('popup-lonlat-closer');
//var popupLonLatOverlay = new Overlay({ element: popupLonLat, autoPan: true, autoPanAnimation: { duration: 250 } });
//popupLonLatCloser.onclick = function () { popupLonLatOverlay.setPosition(undefined); popupLonLatCloser.blur(); return false; };
//
